( function( $, window, document, undefined ) {

	var $fav = $('.favourites-link').find('.fav-count');
	var $cards = $('.card--favourite');

	$(document).on('favorites-updated-single', function(event, favorites, post_id, site_id, status){
		$fav_count = Object.keys(favorites[0].posts).length;
		$('.favourites-link').attr('data-count',$fav_count);
		$fav.text($fav_count);
	});

	$(document).on('favorites-cleared', function(event, button){
		location.reload();
	});

} )( jQuery, window, document );