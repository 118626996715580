/**
 * Open and close submenus in a11y friendly way.
 */

(function ($, window, document, undefined) {

	let $toggle = $('.js-site-nav-toggle');
	let $site_nav = $('.megamenu');
	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;

	const IS_DESKTOP_NAV_MQ = '(min-width: 992px)';

	$toggle.off('click').on('click', function () {
		if ($site_nav.hasClass('is-active')) {
			closeMenu();
		} else {
			openMenu();
		}
	});

	function openMenu() {

		let $triggers = $('.js-toggle-submenu');

		// init a11y attrs
		$triggers.each((index, trigger) => {
			$(trigger).attr('aria-expanded', 'false');
			$(trigger).next().attr('aria-hidden', 'true');
			$(trigger).attr('aria-expanded', 'false');
			$(trigger).next().attr('aria-hidden', 'true');
		});
		$site_nav.addClass('is-active');
		$toggle.addClass('is-active');
		$site_nav.attr('aria-expanded', 'true');
		$site_nav.attr('aria-hidden', 'false');
		if (!window.matchMedia(IS_DESKTOP_NAV_MQ).matches) {
			disableBodyScroll($site_nav[0]);
		}

	}

	function closeMenu() {
		$site_nav.removeClass('is-active');
		$toggle.removeClass('is-active');
		$site_nav.attr('aria-expanded', 'false');
		$site_nav.attr('aria-hidden', 'true');

		if (!window.matchMedia(IS_DESKTOP_NAV_MQ).matches) {
			enableBodyScroll($site_nav[0]);
		}

	}


})(jQuery, window, document);