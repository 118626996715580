(function ($, window, document, undefined) {

	let $alertBox = $('#alert-box');

	if (!$alertBox.length) {
		// return early if alert is disabled
		return;
	}

	/**
	 * Create a 32 bit hash from a string.
	 * @param  {String} str
	 * @return {Number}
	 */
	function hash(str) {
		var hash = 0,
			i, chr;
		for (i = 0; i < str.length; i++) {
			chr = str.charCodeAt(i);
			hash = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}

	// get the stored hash of the previous content
	// undefined if cookie has not been set
	let prevHash = Cookies.get('alertBoxHash');
	let newHash = hash($alertBox.html());

	// if hash (content) has changed since last run, force the alert
	// will also be true if cookie was never set or expired
	if (prevHash != newHash) {
		$.featherlight($('#alert-box'), {
			beforeClose: function setCookie(event) {
				// store seen content in cookie as hash
				Cookies.set('alertBoxHash', newHash, {
					expires: 1
				});
			}
		});
	}




})(jQuery, window, document);


jQuery(document).ready(function () {
	jQuery('.colour').on('click', function () {
		// Example of dynamic content
		var dynamicContent = jQuery(this).find('.overlay').html();

		// Open the Featherlight modal with the dynamic content
		jQuery.featherlight(dynamicContent, {
			variant: 'colour-popup'
		});
	});
});