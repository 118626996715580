( function( $, window, document, undefined ) {

	var site_root = $('html');

	function zoom(scale) {
		var transform = scene.getTransform();
		var currentScale = transform.scale;
		var parentBoundingBox = zoomContainer.getBoundingClientRect();

		scene.zoomTo(parentBoundingBox.width / 2, parentBoundingBox.height / 2, scale);
	}

	if ( !site_root.hasClass('no-object-fit') ) {

		var element = document.querySelector('#zoomable-map');
		var $zoomIn = $('.zoom-in');
		var $zoomOut = $('.zoom-out');
		var zoomContainer = document.querySelector('.map__image');

		var scene = panzoom(element, {
			zoomSpeed: 0.2,
		  	maxZoom: 4,
		  	minZoom: 0.75,
		  	zoomDoubleClickSpeed: 1,
		  	transformOrigin: {x: 0, y: 0},
		  	beforeWheel: function(e) {
			    // allow wheel-zoom only if altKey is down. Otherwise - ignore
			    var shouldIgnore = !e.altKey;
			    return shouldIgnore;
			  }
		});

		$zoomIn.on( 'click', function() {
			zoom(1.5);
		} );

		$zoomOut.on( 'click', function() {
			zoom(0.6666666);
		} );

		
	}


} )( jQuery, window, document );
